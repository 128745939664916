import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Hero from '../components/Hero';
import Container from '../components/Container';
import { H1 } from '../components/StyledHeaders';

const NotFoundPage = () => (
	<Layout>
		<SEO title="404: Not found" />

		<Hero height={[ '40vh', '45vh' ]}>
			<Container>
				<div style={{ maxWidth: 720 }}>
					<H1>NOT FOUND</H1>
					<p>You just hit a route that doesn&#39;t exist... the sadness.</p>
				</div>
			</Container>
		</Hero>
	</Layout>
);

export default NotFoundPage;
